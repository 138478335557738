<template>
  <div>
    <v-icon color="orange"
            small
            class="mr-2"
            @click="updateUser"
            :disabled="dataDelete.length > 0"
            v-if="checkUserPermission('EDIT')"
    >mdi-pencil
    </v-icon>

    <v-icon color="red"
            small
            @click="deleteItem"
            
    > mdi-delete
    </v-icon>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  props: ['darkmode', "item", "params", "dataDelete", "userPermission"],
  data() {
    return {
      validPassword: true,
      user: {
        _id: null,
        user_name: null,
        password: null
      },
      dialogListUser: false,
      loading: false,
      dialogChangePassword: false,
      dialogDelete: false,
      userChild: [],
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ['_id'],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null
      }
    };
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
  },
  methods: {
    async updateUser() {
      await this.fetchCurrentConfigData(this.item);
      this.fetchPopUp(true);
    },

    cancelDelete() {
      this.dialogDelete = false
    },

    async deleteItem() {
      await this.deleteData({
        vm: this,
        data: { _id: this.item._id}
      });
      await this.fetchListStatement(this.params)
      this.dialogDelete = false;
    },

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_playerWhiteList", ["fetchPopUp", "fetchCurrentConfigData", "deleteData", "fetchGetAllConfigUser","fetchListStatement","updateLimit"]),
  },
};
</script>
